export const englishDataEn = {
  home: "HOME",
  about_us: "ABOUT US",
  services: "SERVICES",
  portfolio: "PORTFOLIO",
  project: "PROJECT",
  clients: "CLIENTS",
  contact_us: "CONTACT US",
  //////
  header_welcome: "WELCOME TO",
  name_company: "ROMEYA",
  description:
    "romeya is a premier full service digital marketing agency based in qatar & egypt.",
  mail_us: "MAIL US",
  call_us: "CALL US",
  /////////////
  about: "ABOUT",
  info_about:
    "Romeya is a premier full-service digital marketing agency based in Qatar & Egypt. We specialize in delivering innovative and effective marketing solutions that help businesses thrive and excel in today's competitive market",
  about_num_1: "Years of Experience",
  about_num_2: "Completed Projects",
  about_num_3: "Skilled Professionals",
  about_text_botton_1: "let's start working together",
  about_text_botton_2: "GET IN TOUCH WITH US!",
  /////////////////
  cases: "DISCOVER",
  latest: "LATEST",
  projects: "PROJECTS",
  ///////////////
  branding: "BRANDING",
  elle_profile: "ELLE PROFILE",
  view_all: "VIEW ALL",
  view_more: "VIEW MORE",
  ///////////////
  for_more: "for more",
  what_we: "WHAT WE",
  offer: "OFFER",
  digital_marketing: "DIGITAL MARKETING",
  web_development: "WEB DEVELOPMENT",
  media_productions: "MEDIA PRODUCTIONS",
  ///////////////
  our: "OUR",
  clients_info:
    "we are proud to work with a wide range of clients, from small businesses to large enterprises, we are committed to helping our clients achieve their marketing goals, no matter what their size or industry.",
  ////////////////
  have_your_dream: "Have your dream Design Now!",
  trusted_customer: "TRUST CUSTOMER",
  no_data: "There are no data",
  ////////////
  e_mail: "E-mail",
  phone_number: "Phone number",
  message: "message",
  write_your_message: "Write your message...",
  send: "send",
  responseContactUs: "The mail has been sent successfully",
  responseContactUsError: "An error occurred when sending mail",
  customer_reviews: "Customer Reviews",
};

export default englishDataEn;
