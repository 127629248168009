import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { TbTextSize } from "react-icons/tb";
import { FaImage, FaVideo } from "react-icons/fa";
import { ImEmbed2 } from "react-icons/im";
import { IoCloseCircle } from "react-icons/io5";
import background_img from "../../../../img/background_img.webp";
import background_video from "../../../../img/background_video.webp";
import "../addProject.css";
import { FaAnglesDown, FaAnglesUp } from "react-icons/fa6";

const UpdateProject = () => {
  const { idProject } = useParams();
  const [allDataProject, setAllDataProject] = useState([]);
  const [allDataCategory, setAllDataCategory] = useState([]);

  const [watchData, setWatchData] = useState(true);

  const [selectedUpdateImage, setSelectedUpdateImage] = useState(null);

  const { handleSubmit, control, setValue, watch } = useForm();
  const textFields = watch("textFields", []);

  const [selectedImage, setSelectedImage] = useState(null);
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const [addProject, setAddProject] = useState({
    title_project_en: "",
    title_project_ar: "",
    range_padding: 0,
    category_name: "",
  });

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const updateTextFields = (dataProject) => {
    if (dataProject != null) {
      const newData = dataProject?.map((value, index) => {
        if (value.includes("img_project")) {
          return { type: "image", value, index };
        } else if (value.includes("video_project")) {
          return { type: "video", value, index };
        } else if (value.includes("iframe")) {
          return { type: "embed", value, index };
        } else {
          return { type: "text", value, index };
        }
      });

      setValue("textFields", [...textFields, ...newData]);
    }
  };

  const fetchDataProject = async () => {
    const formDataViewProject = new FormData();
    formDataViewProject.append("validation", validation);
    formDataViewProject.append("idProject", idProject);

    try {
      const responseProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewProject,
        }
      );

      const dataProject = await responseProject.json();

      if (dataProject != null) {
        setAllDataProject(dataProject);
        setSelectedUpdateImage(
          `${LinkLikeBachend}${dataProject[0]?.img_project}`
        );

        setAddProject({
          title_project_en: dataProject[0]?.title_project_en,
          title_project_ar: dataProject[0]?.title_project_ar,
          range_padding: dataProject[0]?.range_padding,
          category_name: dataProject[0]?.category_name,
        });

        updateTextFields(dataProject[0]?.information);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDataCategory = async () => {
    const formDataViewCategory = new FormData();
    formDataViewCategory.append("validation", validation);

    try {
      const responseCategory = await fetch(
        `${LinkLikeBachend}read/view_category.php`,
        {
          method: "POST",
          body: formDataViewCategory,
        }
      );
      const dataCategory = await responseCategory.json();
      setAllDataCategory(dataCategory);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleChangeUpdate = (e) => {
    const { name, value } = e.target;
    setAddProject((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setSelectedImage(file);
  };

  const imageUrl = selectedImage
    ? URL.createObjectURL(selectedImage)
    : selectedUpdateImage;

  const fetchWatchData = useCallback(() => {}, [watchData]);

  const logLoad = useRef(true);

  useEffect(() => {
    if (logLoad.current) {
      logLoad.current = false;

      fetchDataProject();
      fetchDataCategory();
    }
  }, [fetchWatchData]);

  const addField = (type) => {
    setValue("textFields", [...textFields, { type, value: "" }]);
  };

  const removeField = (index) => {
    setWatchData(false);
    const updatedTextFields = [...textFields];
    updatedTextFields.splice(index, 1);

    setValue("textFields", updatedTextFields);
    setTimeout(() => {
      setWatchData(true);
    }, 1000);
  };

  const moveFieldUp = (index) => {
    setWatchData(false);

    if (index > 0) {
      const updatedTextFields = [...textFields];
      const tempValue = updatedTextFields[index];
      updatedTextFields[index] = updatedTextFields[index - 1];
      updatedTextFields[index - 1] = tempValue;
      setValue("textFields", updatedTextFields);
    }
    setTimeout(() => {
      setWatchData(true);
    }, 500);
  };

  const moveFieldDown = (index) => {
    setWatchData(false);

    if (index < textFields.length - 1) {
      const updatedTextFields = [...textFields];
      const tempValue = updatedTextFields[index];

      updatedTextFields[index] = updatedTextFields[index + 1];
      updatedTextFields[index + 1] = tempValue;
      setValue("textFields", updatedTextFields);
    }
    setTimeout(() => {
      setWatchData(true);
    }, 500);
  };

  const onSubmit = async (data) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("idProject", allDataProject[0].id);
    FormDataSubmit.append("img_project", selectedImage);

    Object.entries(addProject).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "textFields") {
        FormDataSubmit.append(key, data[key]);
      }
    }

    if (data.textFields) {
      data.textFields.forEach((textField, index) => {
        if (textField.type === "image" && textField.value instanceof File) {
          FormDataSubmit.append(`image[${index}]`, textField.value);
        } else if (
          textField.type === "video" &&
          textField.value instanceof File
        ) {
          FormDataSubmit.append(`video[${index}]`, textField.value);
        } else {
          FormDataSubmit.append(`textFields[${index}]`, textField.value);
        }
      });
    }

    const response = await fetch(
      `${LinkLikeBachend}update/update_project.php`,
      {
        method: "POST",
        body: FormDataSubmit,
      }
    );

    const resultText = await response.text();

    if (response.ok) {
      setDisabledSubmit(false);

      if (resultText == "successfully") {
        let img_project = document.querySelector("#img_project");
        img_project.value = "";

        setDisabledSubmit(false);

        toast.success("Data update successfully");
      } else if (resultText == "no_changes") {
        setDisabledSubmit(false);

        toast.success("No changes");
      } else {
        toast.error("Error when updating data");

        console.log(resultText);
        setDisabledSubmit(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error when updating data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
      setDisabledSubmit(false);
    }
  };

  // console.log(allDataProject);
  // console.log(addProject);
  // console.log(textFields);

  return (
    <div className="project_admin">
      <h2>Update Project</h2>
      <form
        className="project_form"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div className="added_content">
          {watchData &&
            textFields != null &&
            textFields?.map((field, index) => (
              <div
                key={index}
                className="full_images_video_added"
                style={{ paddingBottom: `${addProject.range_padding}px` }}
              >
                {field.type === "text" && (
                  <>
                    <div>
                      <label>{`Text ${index + 1}:`}</label>
                      <Controller
                        name={`textFields[${index}].value`}
                        control={control}
                        defaultValue={field.value}
                        render={({ field }) => <input {...field} required />}
                      />
                    </div>

                    <IoCloseCircle
                      className="remove_field"
                      onClick={() => removeField(index)}
                    />

                    <div className="down_up_item">
                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldUp(index)}
                        disabled={index === 0}
                      >
                        <FaAnglesUp />
                      </button>

                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldDown(index)}
                        disabled={index === textFields.length - 1}
                      >
                        <FaAnglesDown />
                      </button>
                    </div>
                  </>
                )}

                {field.type === "image" && (
                  <>
                    <div>
                      <label>{`Image ${index + 1}:`}</label>
                      <Controller
                        name={`textFields[${index}].value`}
                        control={control}
                        defaultValue={field.value}
                        render={({ field }) => (
                          <>
                            <div className="images_added_upload">
                              <div className="video_added_icon">
                                <input
                                  type="file"
                                  accept="image/*"
                                  {...(field.value ? {} : { required: true })}
                                  onChange={(e) =>
                                    field.onChange(e.target.files[0])
                                  }
                                />
                                <img
                                  src={background_video}
                                  alt={`Image ${index + 1}`}
                                />
                              </div>

                              {typeof field.value === "string" &&
                              field.value.includes("img_project") ? (
                                <img
                                  src={`${LinkLikeBachend}${field.value}`}
                                  alt={`Image ${index + 1}`}
                                />
                              ) : (
                                <img
                                  src={
                                    field.value
                                      ? URL.createObjectURL(field.value)
                                      : background_img
                                  }
                                  alt={`Image ${index + 1}`}
                                />
                              )}
                            </div>

                            <IoCloseCircle
                              className="remove_field"
                              onClick={() => removeField(index)}
                            />
                          </>
                        )}
                      />
                    </div>

                    <div className="down_up_item">
                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldUp(index)}
                        disabled={index === 0}
                      >
                        <FaAnglesUp />
                      </button>

                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldDown(index)}
                        disabled={index === textFields.length - 1}
                      >
                        <FaAnglesDown />
                      </button>
                    </div>
                  </>
                )}

                {field.type === "video" && (
                  <>
                    <div>
                      <label>{`video ${index + 1}:`}</label>
                      <Controller
                        name={`textFields[${index}].value`}
                        control={control}
                        defaultValue={field.value}
                        render={({ field }) => (
                          <>
                            <div className="video_added">
                              <div className="video_added_icon">
                                <input
                                  type="file"
                                  accept="video/*"
                                  {...(field.value ? {} : { required: true })}
                                  onChange={(e) =>
                                    field.onChange(e.target.files[0])
                                  }
                                />

                                <img
                                  src={background_video}
                                  alt="background_video"
                                  className="background_video"
                                />
                              </div>

                              {typeof field.value === "string" &&
                              field.value.includes("video_project") ? (
                                <video
                                  controls
                                  src={`${LinkLikeBachend}${field.value}`}
                                  alt={`Image ${index + 1}`}
                                ></video>
                              ) : (
                                <video
                                  controls
                                  src={
                                    field.value
                                      ? URL.createObjectURL(field.value)
                                      : null
                                  }
                                  alt={`Image ${index + 1}`}
                                ></video>
                              )}
                            </div>

                            <IoCloseCircle
                              className="remove_field"
                              onClick={() => removeField(index)}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div className="down_up_item">
                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldUp(index)}
                        disabled={index === 0}
                      >
                        <FaAnglesUp />
                      </button>

                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldDown(index)}
                        disabled={index === textFields.length - 1}
                      >
                        <FaAnglesDown />
                      </button>
                    </div>
                  </>
                )}

                {field.type === "embed" && (
                  <>
                    <div>
                      <label>{`Embed ${index + 1}:`}</label>
                      <Controller
                        name={`textFields[${index}].value`}
                        control={control}
                        defaultValue={field.value}
                        render={({ field }) => <textarea {...field} required />}
                      />
                    </div>

                    <IoCloseCircle
                      className="remove_field"
                      onClick={() => removeField(index)}
                    />

                    <div className="down_up_item">
                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldUp(index)}
                        disabled={index === 0}
                      >
                        <FaAnglesUp />
                      </button>

                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldDown(index)}
                        disabled={index === textFields.length - 1}
                      >
                        <FaAnglesDown />
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>

        <div className="data_form">
          <div className="add_containt">
            <button type="button" onClick={() => addField("image")}>
              <p>
                <FaImage />
              </p>
              Image
            </button>
            <button type="button" onClick={() => addField("text")}>
              <p>
                <TbTextSize />
              </p>
              Text
            </button>
            <button type="button" onClick={() => addField("video")}>
              <p>
                <FaVideo />
              </p>
              Video
            </button>

            <button type="button" onClick={() => addField("embed")}>
              <p>
                <ImEmbed2 />
              </p>
              Embed
            </button>
          </div>

          <div>
            <label>Featured image:</label>
            <div className="div_img_project">
              <input
                type="file"
                name="img_project"
                id="img_project"
                accept="image/*"
                onChange={handleFileChange}
              />
              {imageUrl && (
                <div>
                  <img src={imageUrl} alt="background_img" />
                </div>
              )}
            </div>
          </div>

          <div className="all_data_category">
            <label>category:</label>
            <select
              name="category_name"
              id="category_name"
              required
              value={addProject.category_name || ""}
              onChange={handleChangeUpdate}
            >
              <option value="">Choose the category</option>
              {allDataCategory &&
                allDataCategory.length > 0 &&
                allDataCategory.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.category_name}
                  </option>
                ))}
            </select>
          </div>

          <div className="title_project">
            <label>Title (AR):</label>
            <input
              type="text"
              name="title_project_ar"
              id="title_project_ar"
              placeholder="عنوان"
              required
              value={addProject.title_project_ar || ""}
              onChange={handleChangeUpdate}
            />
          </div>

          <div className="title_project">
            <label>Title (EN):</label>
            <input
              type="text"
              name="title_project_en"
              id="title_project_en"
              placeholder="title en"
              required
              value={addProject.title_project_en || ""}
              onChange={handleChangeUpdate}
            />
          </div>

          <div className="range_padding">
            <label htmlFor="range_padding">
              padding {addProject.range_padding}
            </label>
            <input
              type="range"
              id="range_padding"
              name="range_padding"
              min="0"
              max="100"
              required
              value={addProject.range_padding || ""}
              onChange={handleChangeUpdate}
            />
          </div>

          <input
            type="submit"
            value="Update"
            disabled={isDisabledSubmit}
            style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateProject;
