import React from "react";
import "./header.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import buttonPortfolio from "../../../img/button.png";
import flying_saucer from "../../../img/flying_saucer.webp";
import flying_saucer_ar from "../../../img/flying_saucer_ar.webp";
import { RiArrowDownDoubleFill } from "react-icons/ri";

const Header = () => {
  const { i18n, t } = useTranslation();
  let currentLanguage = i18n.language;

  return (
    <div id="header">
      <div className="header">
        <div className="left">
          <h2>{t("name_company")}</h2>
          <h3 className="description">{t("description")}</h3>
          <div className="call">
            <div>
              <p>{t("mail_us")}</p>
              <p>
                <a href="mailto:info@romeya.com" target="_blank">
                  info@romeya.com
                </a>
              </p>
            </div>
            <div>
              <p>{t("call_us")}</p>
              <p>
                <a href="tel:+97470100403">+974 7010 0403</a>
              </p>
            </div>
          </div>

          <Link
            to="project"
            spy={true}
            smooth={true}
            offset={-200}
            duration={500}
            className="portfolio_header"
          >
            {t("portfolio")}
            <img src={buttonPortfolio} alt="buttonPortfolio" />
          </Link>

          <RiArrowDownDoubleFill />
        </div>
        <div className="right">
          {currentLanguage == "en" ? (
            <img
              src={flying_saucer}
              alt="flying_saucer"
              className="rotate-and-move"
            />
          ) : (
            <img
              src={flying_saucer_ar}
              alt="flying_saucer_ar"
              className="flying_saucer_ar rotate-and-move"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
