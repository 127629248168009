import React from "react";
import "./clients.css";
import { useTranslation } from "react-i18next";
import SliderClients from "./sliderClients/SliderClients";
import rock_pioneer from "../../../img/rock_pioneer.webp";
import Footer from "../../../components/footer/Footer";

const Clients = () => {
  const { t } = useTranslation();

  return (
    <div id="clients">
      <div className="clients">
        <h2>{t("our")}</h2>
        <h2>{t("clients")}</h2>
        <p>{t("clients_info")}</p>

        <div className="slider">
          <SliderClients />
        </div>
      </div>
      <img src={rock_pioneer} alt="rock_pioneer" className="rock_pioneer" />
      <Footer />
    </div>
  );
};

export default Clients;
