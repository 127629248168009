import React, { useEffect, useRef, useState } from "react";
import { MdOutlineZoomIn } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Grid, Pagination, Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../../components/context/AppProvider";
import ViewProject from "../viewProject/ViewProject";

const SliderProject = () => {
  const [allDataProject, setAllDataProject] = useState([]);
  const [allDataCategory, setAllDataCategory] = useState([]);
  const { direction } = useAppContext();
  const [viewProject, setViewProject] = useState(false);
  const [idProject, setIdProject] = useState(0);

  const { i18n, t } = useTranslation();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataProject = async () => {
    const formDataViewProject = new FormData();
    formDataViewProject.append("validation", validation);

    try {
      const responseProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewProject,
        }
      );
      const dataProject = await responseProject.json();
      setAllDataProject(dataProject);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchDataCategory = async () => {
    const formDataViewCategory = new FormData();
    formDataViewCategory.append("validation", validation);

    try {
      const responseCategory = await fetch(
        `${LinkLikeBachend}read/view_category.php`,
        {
          method: "POST",
          body: formDataViewCategory,
        }
      );
      const dataCategory = await responseCategory.json();
      setAllDataCategory(dataCategory);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const logLoad = useRef(true);

  useEffect(() => {
    if (logLoad.current) {
      logLoad.current = false;

      fetchDataProject();
      fetchDataCategory();
    }
  }, []);

  const handleIdProject = (idProject) => {
    setIdProject(idProject);
    setViewProject(true);
  };

  // console.log(allDataProject);
  // console.log(allDataCategory);

  return (
    <>
      {viewProject && (
        <ViewProject idProject={idProject} setViewProject={setViewProject} />
      )}
      <Swiper
        slidesPerView={7}
        grid={{
          rows: 2,
        }}
        // loop={true}
        spaceBetween={17}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          768: {
            slidesPerView: 7,
            spaceBetween: 17,
          },
          530: {
            slidesPerView: 5,
            spaceBetween: 7,
          },
          0: {
            slidesPerView: 3,
            spaceBetween: 7,
          },
        }}
        navigation={true}
        modules={[Grid, Autoplay, Pagination, Navigation]}
      >
        {allDataProject &&
          allDataProject.length > 0 &&
          allDataProject.map((item, i) =>
            i18n.language == "en" ? (
              <SwiperSlide
                key={i}
                virtualIndex={i}
                onClick={() => handleIdProject(item.id)}
              >
                <div className="all_project_item">
                  <img
                    src={`${LinkLikeBachend}${item.img_project}`}
                    alt={item.title_project_en}
                  />

                  <div className="div_info_show">
                    <MdOutlineZoomIn />
                    <p className="p_1">
                      {allDataCategory &&
                        allDataCategory?.length > 0 &&
                        allDataCategory?.map(
                          (category) =>
                            category.id == item.category_name &&
                            category.category_name
                        )}
                    </p>
                    <h3>{item.title_project_en}</h3>
                    <p className="p_2">{t("view_more")}</p>
                  </div>
                </div>
              </SwiperSlide>
            ) : (
              <SwiperSlide
                key={i}
                virtualIndex={i}
                onClick={() => handleIdProject(item.id)}
              >
                <div className="all_project_item">
                  <img
                    src={`${LinkLikeBachend}${item.img_project}`}
                    alt={item.title_project_ar}
                  />

                  <div className="div_info_show">
                    <MdOutlineZoomIn />
                    <p className="p_1">
                      {allDataCategory &&
                        allDataCategory.length > 0 &&
                        allDataCategory.map(
                          (category) =>
                            category.id == item.category_name &&
                            category.category_name
                        )}
                    </p>
                    <h3>{item.title_project_ar}</h3>
                    <p className="p_2">{t("view_more")}</p>
                  </div>
                </div>
              </SwiperSlide>
            )
          )}
      </Swiper>
    </>
  );
};

export default SliderProject;
