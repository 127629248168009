export const arabicDataAr = {
  home: "الرئيسية",
  about_us: "معلومات عنا",
  services: "خدماتنا",
  portfolio: "ملف الشركة",
  project: "مشاريع",
  clients: "العملاء",
  contact_us: "اتصل بنا",
  //////
  header_welcome: "مرحبا بك في",
  name_company: "روميا",
  description:
    "روميا هي وكالة تسويق رقمية رائدة متكاملة الخدمات ومقرها في قطر ومصر.",
  mail_us: "البريد",
  call_us: "اتصل بنا",
  /////////////
  about: "معلومات عنا",
  info_about:
    "روميا هي وكالة رائدة للتسويق الرقمي متكاملة الخدمات مقرها في قطر ومصر. نحن متخصصون في تقديم حلول تسويقية مبتكرة وفعالة تساعد الشركات على الازدهار والتفوق في السوق التنافسية اليوم",
  about_num_1: "سنوات من الخبرة",
  about_num_2: "المشاريع المنجزة",
  about_num_3: "المهنيين المهرة",
  about_text_botton_1: "لنبدأ العمل معًا",
  about_text_botton_2: "ابق على تواصل معنا!",
  /////////////////
  cases: "اكتشف",
  latest: "أحدث",
  projects: "المشاريع",
  ///////////////
  branding: "العلامة التجارية",
  elle_profile: "ملف تعريف",
  view_all: "عرض جميع",
  view_more: "عرض المزيد",
  ///////////////
  for_more: "المزيد",
  what_we: "ما",
  offer: "نقدمه",
  digital_marketing: "التسويق الرقمي",
  web_development: "تطوير المواقع",
  media_productions: "إنتاج وسائل الإعلام",
  ///////////////
  our: "",
  clients_info:
    "نحن فخورون بالعمل مع مجموعة واسعة من العملاء، من الشركات الصغيرة إلى المؤسسات الكبيرة، ونحن ملتزمون بمساعدة عملائنا على تحقيق أهدافهم التسويقية، بغض النظر عن حجمهم أو مجالهم.",
  ////////////////
  have_your_dream: "احصل على تصميم أحلامك الآن!",
  trusted_customer: "عميل ثقة",
  no_data: "لا يوجد بيانات",
  ////////////
  e_mail: "بريد إلكتروني",
  phone_number: "رقم الهاتف",
  message: "الرسالة",
  write_your_message: "اكتب رسالتك...",
  send: "ارسال",
  responseContactUs: "تم ارسال البريد بنجاح",
  responseContactUsError: "حدث خطأ عند ارسال البريد",
  customer_reviews: "آراء العملاء",
};

export default arabicDataAr;
