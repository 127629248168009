import React, { useEffect, useState } from "react";
import "./navAdmin.css";
import Cookies from "js-cookie";
import { IoIosLogOut } from "react-icons/io";
import { FaHome } from "react-icons/fa";
import { IoPeopleOutline, IoLockClosedOutline } from "react-icons/io5";
import { NavLink, Navigate, Outlet, useLocation } from "react-router-dom";
import {
  MdMenuOpen,
  MdOutlineDataset,
  MdAdminPanelSettings,
} from "react-icons/md";
import { TbCategoryPlus } from "react-icons/tb";

const Navigation = () => {
  const [navActive, setNavActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(1);
  const [selectDropdown, setSelectDropdown] = useState({});
  const [isShow, setShow] = useState("hide");
  const location = useLocation();

  useEffect(() => {
    const index = ulLiLinks.findIndex((item) => item.to === location.pathname);
    if (index !== -1) {
      if (index != 0) {
        setSelectedItem(index);
      }
    }
  }, []);

  const handleNavActive = () => {
    setNavActive(!navActive);
  };

  const ulLiLinks = [
    {
      dropdown: "",
      icon: <MdAdminPanelSettings />,
      title: "Admin",
      to: "/dashboard",
    },
    { dropdown: "", icon: <FaHome />, title: "Home", to: "/dashboard" },
    {
      dropdown: "",
      icon: <IoPeopleOutline />,
      title: "Slider Clients",
      to: "/dashboard/clients",
    },
    {
      dropdown: "true",
      icon: <MdOutlineDataset />,
      title: "Project",
      to: "",
    },
    {
      dropdown: "",
      icon: <IoLockClosedOutline />,
      title: "Change password",
      to: "/dashboard/change-password-admin",
    },
    { dropdown: "", icon: <IoIosLogOut />, title: "Sign Out", to: "signOut" },
  ];

  const navDropdown = {
    Project: [
      {
        icon: <TbCategoryPlus />,
        title: "Category Project",
        to: "/dashboard/category-project",
      },
      {
        icon: <TbCategoryPlus />,
        title: "All Project",
        to: "/dashboard/project",
      },
      {
        icon: <TbCategoryPlus />,
        title: "Add Project",
        to: "/dashboard/add-project",
      },
    ],
  };

  const handleClickSelected = (index) => {
    setSelectedItem(index);
  };

  const handleSignOut = () => {
    Cookies.remove("login_admin");
    // window.location.reload();
  };

  const authSuccess = Cookies.get("login_admin");

  if (authSuccess !== "true") {
    return <Navigate to="/admin" />;
  }

  const handlehandleDropdown = (dropdown) => {
    for (const key in navDropdown) {
      if (key === dropdown) {
        setSelectDropdown(navDropdown[key]);
        break;
      }
    }

    isShow == "hide" ? setShow("show") : setShow("hide");
  };

  return (
    <div className="container_admin">
      <section className="section_admin">
        <Outlet />
      </section>

      <div className="navbar_admin">
        <MdMenuOpen onClick={handleNavActive} className="MdMenuOpen" />
        <div className={`navigation ${navActive ? "" : "active"}`}>
          <ul>
            {ulLiLinks.map((link, i) => (
              <li
                key={i}
                className={selectedItem == i ? "hovered" : ""}
                onClick={() => handleClickSelected(i)}
              >
                {link.to == "signOut" ? (
                  <a className="signOut" onClick={handleSignOut}>
                    <span className="icon">{link.icon}</span>
                    <span className="title">{link.title}</span>
                  </a>
                ) : link.dropdown == "true" ? (
                  <>
                    <a
                      className="dropdown"
                      onClick={() => handlehandleDropdown(link.title)}
                    >
                      <span className="icon">{link.icon}</span>
                      <span className="title">{link.title}</span>
                    </a>

                    <div className={`dropdown-container ${isShow}`}>
                      {selectDropdown.length > 0 &&
                        selectDropdown.map((item, i) => (
                          <NavLink to={item.to} key={i}>
                            <span className="icon">{item.icon}</span>
                            <span className="title">{item.title}</span>
                          </NavLink>
                        ))}
                    </div>
                  </>
                ) : (
                  <NavLink to={link.to}>
                    <span className="icon">{link.icon}</span>
                    <span className="title">{link.title}</span>
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
