import React from "react";

const AllDataComponent = ({ allDataProject, rangePadding }) => {
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  return (
    <div className="added_content_added">
      {allDataProject != null ? (
        Object?.keys(allDataProject)?.map((index) => {
          const data = allDataProject[index];

          if (data?.includes("iframe")) {
            return (
              <div
                key={index}
                style={{
                  paddingBottom: `${rangePadding}px`,
                  lineHeight: "0",
                }}
                dangerouslySetInnerHTML={{ __html: data }}
              ></div>
            );
          }

          if (data?.includes("img_project")) {
            return (
              <div
                key={index}
                style={{
                  paddingBottom: `${rangePadding}px`,
                  lineHeight: "0",
                }}
              >
                <img src={`${LinkLikeBachend}${data}`} alt={`Image ${index}`} />
              </div>
            );
          }

          if (data?.includes("video_project")) {
            return (
              <div
                key={index}
                style={{
                  paddingBottom: `${rangePadding}px`,
                  lineHeight: "0",
                }}
              >
                <video controls>
                  <source src={`${LinkLikeBachend}${data}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            );
          }

          return (
            <div
              key={index}
              style={{ paddingBottom: `${rangePadding}px`, lineHeight: "0" }}
            >
              <p>{data}</p>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default AllDataComponent;
