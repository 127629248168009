import React, { useEffect } from "react";
import NotFound from "./page/notFound/NotFound";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import { useAppContext } from "./components/context/AppProvider";
import Home from "./page/home/Home";
import Portfolio from "./page/portfolio/Portfolio";
import Authentication from "./admin/Authentication";
import AdminClients from "./admin/dashboard/adminClients/AdminClients";
import AdminProject from "./admin/dashboard/adminProject/AdminProject";
import AddProject from "./admin/dashboard/adminProject/addProject/AddProject";
import CategoryProject from "./admin/dashboard/adminProject/categoryProject/CategoryProject";
import ViewProject from "./page/home/project/viewProject/ViewProject";
import UpdateProject from "./admin/dashboard/adminProject/updateProject/UpdateProject";
import HomeAdmin from "./admin/dashboard/home/HomeAdmin";
import ContactUs from "./page/home/contactUs/ContactUs";
import NavAdmin from "./admin/dashboard/navAdmin/NavAdmin";
import ChangePasswordAdmin from "./admin/dashboard/changePasswordAdmin/ChangePasswordAdmin";

const App = () => {
  const location = useLocation();
  const { direction, setDirection } = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      className={`direction direction_${direction}`}
      style={{ direction: direction }}
    >
      <Routes>
        <Route
          path="/"
          element={<Navbar setDirection={setDirection} direction={direction} />}
        >
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/Contact-us" element={<ContactUs />} />
          <Route path="/view-project/:idProject" element={<ViewProject />} />
        </Route>

        <Route path="/admin" element={<Authentication />} />

        <Route path="/dashboard" element={<NavAdmin />}>
          <Route path="/dashboard" element={<HomeAdmin />} />
          <Route path="clients" element={<AdminClients />} />
          <Route path="category-project" element={<CategoryProject />} />
          <Route path="project" element={<AdminProject />} />
          <Route path="add-project" element={<AddProject />} />
          <Route path="update-project/:idProject" element={<UpdateProject />} />
          <Route
            path="change-password-admin"
            element={<ChangePasswordAdmin />}
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
