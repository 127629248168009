import React, { useEffect, useRef, useState } from "react";
import "./navbar.css";
import logo from "../../img/logo.png";
import logo_ar from "../../img/logo_ar.webp";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import Footer from "../footer/Footer";
import Copyrights from "../copyrights/Copyrights";
import SocialMediaLinks from "../socialMediaLinks/SocialMediaLinks";

const Navbar = ({ setDirection, direction }) => {
  const [isChecked, setChecked] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const location = useLocation();
  const myElementRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        const targetId = location.hash.substring(1);
        const targetElement = document.getElementById(targetId);
        const height = myElementRef.current.clientHeight;

        if (targetElement) {
          const offset = height;
          const elementTop =
            targetElement.getBoundingClientRect().top + window.scrollY;
          const offsetTop = elementTop - offset;

          window.scrollTo({ top: offsetTop, behavior: "smooth" });
        }
      }
    }, 300);

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    if (location.pathname == "/") {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showFooter, location.pathname]);

  const handleToggle = () => {
    setChecked(!isChecked);
  };

  const { i18n, t } = useTranslation();
  let currentLanguage = i18n.language;

  const ChangeEN = () => {
    i18n.changeLanguage("en");
    setDirection("ltr");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
    setDirection("rtl");
  };

  const links = [
    { to: "header", nameT: t("home") },
    { to: "about", nameT: t("about_us") },
    { to: "services", nameT: t("services") },
    { to: "portfolio", nameT: t("portfolio") },
    { to: "project", nameT: t("project") },
    { to: "clients", nameT: t("clients") },
    { to: "contact-us", nameT: t("contact_us") },
  ];

  const links_page = [
    { to: "/", nameT: t("home") },
    { to: "/#about", nameT: t("about_us") },
    { to: "/#services", nameT: t("services") },
    { to: "portfolio", nameT: t("portfolio") },
    { to: "/#project", nameT: t("project") },
    { to: "/#clients", nameT: t("clients") },
    { to: "contact-us", nameT: t("contact_us") },
  ];

  const handleNavToggle = () => {
    if (!scrolling) {
      setScrolling(true);
    }
  };

  return (
    <>
      <nav className={scrolling ? "scrolled" : ""} ref={myElementRef}>
        <div className="div_navbar">
          <input
            type="checkbox"
            id="nav-toggle"
            checked={isChecked}
            onChange={handleToggle}
          />

          <Link
            to="header"
            spy={true}
            smooth={true}
            offset={-200}
            duration={500}
            className="logo"
          >
            {currentLanguage == "en" ? (
              <img src={logo} alt="logo" />
            ) : (
              <img src={logo_ar} alt="logo" />
            )}
          </Link>

          <ul className="links" onClick={handleToggle}>
            {showFooter
              ? links.map((item, i) => (
                  <li key={i}>
                    {item.to === "portfolio" ? (
                      <NavLink onClick={handleToggle} to={item.to}>
                        {item.nameT}
                      </NavLink>
                    ) : item.to === "contact-us" ? (
                      <NavLink onClick={handleToggle} to={item.to}>
                        {item.nameT}
                      </NavLink>
                    ) : (
                      <Link
                        onClick={handleToggle}
                        to={item.to}
                        spy={true}
                        smooth={true}
                        offset={-200}
                        duration={500}
                      >
                        {item.nameT}
                      </Link>
                    )}
                  </li>
                ))
              : links_page.map((item, i) => (
                  <li key={i}>
                    {item.to === "portfolio" ? (
                      <NavLink onClick={handleToggle} to={item.to}>
                        {item.nameT}
                      </NavLink>
                    ) : item.to === "contact-us" ? (
                      <NavLink onClick={handleToggle} to={item.to}>
                        {item.nameT}
                      </NavLink>
                    ) : (
                      <NavLink onClick={handleToggle} to={item.to}>
                        {item.nameT}
                      </NavLink>
                    )}
                  </li>
                ))}

            <li>
              <IoSearch className="phone" />
            </li>

            {direction == "ltr" ? (
              <li className="ChangeLang" onClick={ChangeAR}>
                AR
              </li>
            ) : (
              <li className="ChangeLang" onClick={ChangeEN}>
                EN
              </li>
            )}

            <SocialMediaLinks />
          </ul>

          <div className="lang_ar_en_search computer">
            <IoSearch className="computer" />
            <IoSearch className="phone" />

            {direction == "ltr" ? (
              <button className="ChangeLang" onClick={ChangeAR}>
                AR
              </button>
            ) : (
              <button className="ChangeLang" onClick={ChangeEN}>
                EN
              </button>
            )}
          </div>

          <label
            htmlFor="nav-toggle"
            className="icon-burger"
            onClick={handleNavToggle}
          >
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </label>
        </div>
      </nav>
      <Outlet />
      {!showFooter && <Footer />}
      <Copyrights />
    </>
  );
};

export default Navbar;
