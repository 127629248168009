import React, { useEffect, useRef, useState } from "react";
import "./viewProject.css";
import { IoMdCloseCircleOutline } from "react-icons/io";
import AllDataComponent from "../../../../components/allDataComponent/AllDataComponent";

const ViewProject = ({ idProject, setViewProject }) => {
  const [allDataProject, setAllDataProject] = useState([]);
  const [allDataInformation, setAllDataInformation] = useState([]);
  const [rangePadding, setRangePadding] = useState(0);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataProject = async () => {
    const formDataViewProject = new FormData();
    formDataViewProject.append("validation", validation);
    formDataViewProject.append("idProject", idProject);

    try {
      const responseProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewProject,
        }
      );
      const dataProject = await responseProject.json();
      setAllDataProject(dataProject);
      setAllDataInformation(dataProject[0].information);
      setRangePadding(dataProject[0].range_padding);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };
  const handleViewProjectClose = () => {
    let bodyElement = document.body;

    setViewProject(false);
    bodyElement.style.removeProperty("overflow");
  };

  let logLoad = useRef(true);

  useEffect(() => {
    if (logLoad) {
      logLoad = false;

      fetchDataProject();
      let bodyElement = document.body;

      bodyElement.style.overflow = "hidden";
    }
  }, []);

  // console.log(allDataProject);

  return (
    <div id="view_project_pupup">
      <IoMdCloseCircleOutline
        className="project_close"
        onClick={handleViewProjectClose}
      />

      <AllDataComponent
        allDataProject={allDataInformation}
        rangePadding={rangePadding}
      />
    </div>
  );
};

export default ViewProject;
