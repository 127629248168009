import React, { useEffect, useRef, useState } from "react";
import "./projectContent.css";
import { useAppContext } from "../../../components/context/AppProvider";
import { MdOutlineZoomIn } from "react-icons/md";
import { useTranslation } from "react-i18next";
import ViewProject from "../../home/project/viewProject/ViewProject";

const ProjectContent = () => {
  const [allDataProject, setAllDataProject] = useState([]);
  const [allDataCategory, setAllDataCategory] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [viewProject, setViewProject] = useState(false);
  const [idProject, setIdProject] = useState(0);

  const { direction } = useAppContext();
  const { t } = useTranslation();

  const [activeCategory, setActiveCategory] = useState("ALL");

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataProject = async (idCategory) => {
    const formDataViewProject = new FormData();
    formDataViewProject.append("validation", validation);
    if (idCategory !== undefined && idCategory !== null && idCategory !== "") {
      formDataViewProject.append("idCategory", idCategory);
    }

    try {
      const responseProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewProject,
        }
      );
      const dataProject = await responseProject.json();
      setAllDataProject(dataProject);
      setDisabledLoadMore(false);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchDataCategory = async () => {
    const formDataViewCategory = new FormData();
    formDataViewCategory.append("validation", validation);

    try {
      const responseCategory = await fetch(
        `${LinkLikeBachend}read/view_category.php`,
        {
          method: "POST",
          body: formDataViewCategory,
        }
      );
      const dataCategory = await responseCategory.json();
      setAllDataCategory(dataCategory);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchDataprojectCount = async (idCategory) => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);
      if (
        idCategory !== undefined &&
        idCategory !== null &&
        idCategory !== ""
      ) {
        formDataCount.append("idCategory", idCategory);
      }

      const responseprojectCount = await fetch(
        `${LinkLikeBachend}read/view_project_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataprojectCount = await responseprojectCount.text();

      setProjectCount(dataprojectCount);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewprojectPage = new FormData();
    formDataViewprojectPage.append("validation", validation);
    formDataViewprojectPage.append("page", currentPage + 1);

    try {
      const responseproject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewprojectPage,
        }
      );
      const dataproject = await responseproject.json();

      setTimeout(() => {
        setAllDataProject((prevData) => prevData.concat(dataproject));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const logLoad = useRef(true);

  useEffect(() => {
    if (logLoad.current) {
      logLoad.current = false;

      fetchDataProject();
      fetchDataCategory();
      fetchDataprojectCount();
    }
  }, []);

  const handleIdProject = (idProject) => {
    setIdProject(idProject);
    setViewProject(true);
  };

  const handleCategoryClick = (categoryName, idCategory) => {
    setActiveCategory(categoryName);
    setDisabledLoadMore(true);

    fetchDataProject(idCategory);
    fetchDataprojectCount(idCategory);
  };

  // console.log(allDataProject);
  // console.log(projectCount);
  // console.log(allDataCategory);

  return (
    <>
      {viewProject && (
        <ViewProject idProject={idProject} setViewProject={setViewProject} />
      )}
      <div className="project_content" id="projectContent">
        <div className="categories">
          <button
            className={activeCategory === "ALL" ? "active" : ""}
            onClick={() => handleCategoryClick("ALL")}
            disabled={isDisabledLoadMore}
            style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
          >
            ALL
          </button>
          {allDataCategory &&
            allDataCategory.length > 0 &&
            allDataCategory.map((category, i) => (
              <button
                key={i}
                className={
                  activeCategory === category.category_name ? "active" : ""
                }
                onClick={() =>
                  handleCategoryClick(category.category_name, category.id)
                }
                disabled={isDisabledLoadMore}
                style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
              >
                {category.category_name}
              </button>
            ))}
        </div>

        <div className="all_project">
          {allDataProject && allDataProject.length > 0 ? (
            allDataProject.map((item, i) =>
              direction == "ltr" ? (
                <div
                  className="all_project_item"
                  key={i}
                  onClick={() => handleIdProject(item.id)}
                >
                  <img
                    src={`${LinkLikeBachend}${item.img_project}`}
                    alt={item.title_project_en}
                  />

                  <div className="div_info_show">
                    <MdOutlineZoomIn />
                    <p className="p_1">
                      {allDataCategory &&
                        allDataCategory.length > 0 &&
                        allDataCategory.map(
                          (category) =>
                            category.id == item.category_name &&
                            category.category_name
                        )}
                    </p>
                    <h3>{item.title_project_en}</h3>
                    <p className="p_2">{t("view_more")}</p>
                  </div>
                </div>
              ) : (
                <div
                  className="all_project_item"
                  key={i}
                  onClick={() => handleIdProject(item.id)}
                >
                  <img
                    src={`${LinkLikeBachend}${item.img_project}`}
                    alt={item.title_project_ar}
                  />

                  <div className="div_info_show">
                    <MdOutlineZoomIn />
                    <p className="p_1">
                      {allDataCategory &&
                        allDataCategory.length > 0 &&
                        allDataCategory.map(
                          (category) =>
                            category.id == item.category_name &&
                            category.category_name
                        )}
                    </p>
                    <h3>{item.title_project_ar}</h3>
                    <p className="p_2">{t("view_more")}</p>
                  </div>
                </div>
              )
            )
          ) : (
            <p>{t("no_data")}</p>
          )}
        </div>

        <div className="div_load_more">
          {allDataProject &&
            allDataProject.length > 0 &&
            parseInt(projectCount) !== allDataProject.length && (
              <button
                className="button load_more"
                onClick={handleLoadMore}
                disabled={isDisabledLoadMore}
                style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
              >
                {!isDisabledLoadMore ? "Load More" : "Loading..."}
              </button>
            )}
        </div>
      </div>
    </>
  );
};

export default ProjectContent;
