import React from "react";
import "./services.css";
import { useTranslation } from "react-i18next";
import rocket from "../../../img/rocket.webp";
import SliderServices from "./sliderServices/SliderServices";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div id="services">
      <img src={rocket} alt="rocket" className="rocket rotate-and-move" />

      <div className="services">
        <p>{t("what_we")}</p>
        <h2>{t("offer")}</h2>

        <SliderServices />
      </div>
    </div>
  );
};

export default Services;
