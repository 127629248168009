import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const Counter = ({ endNumber }) => {
  const [shouldCount, setShouldCount] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setShouldCount(inView);
    }

    const count = () => {
      setCurrentNumber((prev) => prev + 1);
    };

    const interval = setInterval(() => {
      if (shouldCount && currentNumber <= endNumber - 1) {
        count();
      }
    }, 5);

    return () => clearInterval(interval);
  }, [shouldCount, currentNumber, endNumber, inView]);

  useEffect(() => {
    if (!shouldCount) {
      setCurrentNumber(0);
    }
  }, [shouldCount]);

  return <span ref={ref}>{currentNumber}</span>;
};

export default Counter;
