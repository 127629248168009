import React from "react";
import { LiaCopyrightSolid } from "react-icons/lia";
import "./copyrights.css";

const Copyrights = () => {
  return (
    <div className="copyrights">
      <p>
        <LiaCopyrightSolid /> 2024 All rights reserved​ by{" "}
        <a href="/">Romeya.com</a>
      </p>
    </div>
  );
};

export default Copyrights;
