import React, { useEffect, useState } from "react";
import "./footer.css";
import { Link } from "react-scroll";
import logo from "../../img/logo.png";
import logo_ar from "../../img/logo_ar.webp";
import { useTranslation } from "react-i18next";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import SocialMediaLinks from "../socialMediaLinks/SocialMediaLinks";
import { NavLink, useLocation } from "react-router-dom";

const Footer = ({ offsetLink }) => {
  const { i18n, t } = useTranslation();
  let currentLanguage = i18n.language;
  const [showFooterP, setShowFooterP] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/") {
      setShowFooterP(true);
    } else {
      setShowFooterP(false);
    }
  }, [showFooterP, location.pathname]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const links = [
    { to: "about", nameT: t("about_us") },
    { to: "services", nameT: t("services") },
    { to: "portfolio", nameT: t("portfolio") },
    { to: "project", nameT: t("project") },
    { to: "clients", nameT: t("clients") },
    { to: "contact-us", nameT: t("contact_us") },
  ];

  const links_page = [
    { to: "/#about", nameT: t("about_us") },
    { to: "/#services", nameT: t("services") },
    { to: "portfolio", nameT: t("portfolio") },
    { to: "/#project", nameT: t("project") },
    { to: "/#clients", nameT: t("clients") },
    { to: "contact-us", nameT: t("contact_us") },
  ];

  return (
    <div id="footer">
      <MdKeyboardDoubleArrowUp
        onClick={scrollToTop}
        className="scroll-to-top"
      />

      <div className="footer">
        <div className="all_footer">
          <div className="right">
            <Link
              to="header"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
              className="logo"
            >
              {currentLanguage == "en" ? (
                <img src={logo} alt="logo" />
              ) : (
                <img src={logo_ar} alt="logo" />
              )}
            </Link>
          </div>
          <div className="center">
            {showFooterP ? (
              <Link
                to="header"
                spy={true}
                smooth={true}
                offset={offsetLink}
                duration={500}
                style={{ color: "var(--bg)" }}
              >
                {t("home")}
              </Link>
            ) : (
              <NavLink to="/">{t("home")}</NavLink>
            )}

            <ul className="links">
              {showFooterP
                ? links.map((item, i) => (
                    <li key={i}>
                      {item.to === "portfolio" ? (
                        <NavLink to={item.to}>{item.nameT}</NavLink>
                      ) : item.to === "contact-us" ? (
                        <NavLink to={item.to}>{item.nameT}</NavLink>
                      ) : (
                        <Link
                          to={item.to}
                          spy={true}
                          smooth={true}
                          offset={-200}
                          duration={500}
                        >
                          {item.nameT}
                        </Link>
                      )}
                    </li>
                  ))
                : links_page.map((item, i) => (
                    <li key={i}>
                      {item.to === "portfolio" ? (
                        <NavLink to={item.to}>{item.nameT}</NavLink>
                      ) : item.to === "contact-us" ? (
                        <NavLink to={item.to}>{item.nameT}</NavLink>
                      ) : (
                        <NavLink to={item.to}>{item.nameT}</NavLink>
                      )}
                    </li>
                  ))}
            </ul>
          </div>
          <div className="left">
            <h3 className="last_h3_1">{t("about_text_botton_1")}</h3>

            <NavLink to="contact-us" className="last_h3_2">
              {t("about_text_botton_2")}
            </NavLink>

            <div>
              <SocialMediaLinks />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
