import React, { useState } from "react";
import "./contactUs.css";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ScrollVisibility from "../../../components/ScrollVisibility/ScrollVisibility";

const ContactUs = () => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm();
  const [isDisabledButton, setDisabledButton] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const onSubmit = async (data) => {
    setDisabledButton(true);

    const formData = new FormData();
    formData.append("validation", validation);
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await fetch(`${LinkLikeBachend}read/send_email.php`, {
      method: "POST",
      body: formData,
    });

    const resultText = await response.text();

    setDisabledButton(false);

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success(t("responseContactUs"));

        reset();
      } else {
        toast.error(t("responseContactUsError"));
        console.log(resultText);
        setDisabledButton(false);
      }
    } else {
      const errorText = await response.text();
      toast.error(t("responseContactUsError"));
      console.log("Error - ", errorText);
      setDisabledButton(false);
    }
  };

  return (
    <div id="contact">
      <div className="contact">
        <ScrollVisibility>
          <form onSubmit={handleSubmit(onSubmit)} className="form_contact">
            <div className="firstName_lastName">
              <div>
                <label>{t("e_mail")}</label>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="email"
                      required
                      {...field}
                      placeholder={t("e_mail")}
                    />
                  )}
                />
              </div>

              <div>
                <label>{t("phone_number")}</label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="number"
                      required
                      {...field}
                      placeholder={t("phone_number")}
                    />
                  )}
                />
              </div>
            </div>

            <div className="message">
              <label>{t("message")}</label>
              <Controller
                name="message"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="text"
                    required
                    {...field}
                    placeholder={t("write_your_message")}
                  />
                )}
              />
            </div>

            <div className="submit_button">
              <button
                type="submit"
                style={{ opacity: isDisabledButton ? 0.3 : 1 }}
                disabled={isDisabledButton}
              >
                {t("send")}
              </button>
            </div>
          </form>
        </ScrollVisibility>
        <ScrollVisibility>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7223607.194313267!2d45.94547732442545!3d27.866602302892847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c58d4f571667%3A0xc4de44a5078e6dfe!2sFendi%20Casa%20Doha!5e0!3m2!1sar!2seg!4v1713188846152!5m2!1sar!2seg"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="iframe"
          ></iframe>{" "}
        </ScrollVisibility>
      </div>
    </div>
  );
};

export default ContactUs;
