import React from "react";
import "./project.css";
import { useTranslation } from "react-i18next";
import meteor from "../../../img/meteor.webp";
import buttonPortfolio from "../../../img/button.png";
import { NavLink } from "react-router-dom";
import SliderProject from "./sliderProject/SliderProject";

const Project = () => {
  const { t } = useTranslation();

  return (
    <div id="project">
      <img src={meteor} alt="meteor" className="meteor rotate-and-move" />
      <div className="project">
        <div className="top">
          <p>{t("cases")}</p>
          <h2>
            {t("latest")} {t("project")}
          </h2>
        </div>

        <SliderProject />
      </div>

      <NavLink to="/portfolio" className="view_all_project">
        <span>{t("view_all")}</span>
        <span>{t("projects")}</span>
        <img src={buttonPortfolio} alt="buttonPortfolio" />
      </NavLink>
    </div>
  );
};

export default Project;
