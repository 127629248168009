import React from "react";
import "./socialMediaLinks.css";
import {
  FaWhatsapp,
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa";

const SocialMediaLinks = () => {
  const links_social = [
    {
      href: "https://api.whatsapp.com/send?phone=97470100403",
      icons: <FaWhatsapp />,
    },
    {
      href: "https://www.facebook.com/Romeya.agency",
      icons: <FaFacebookF />,
    },
    {
      href: "https://www.instagram.com/romeya_marketing",
      icons: <FaInstagram />,
    },
  ];

  return (
    <div className="social_media_links">
      {links_social.map((item, i) => (
        <a href={item.href} target="_blank" key={i}>
          {item.icons}
        </a>
      ))}
    </div>
  );
};

export default SocialMediaLinks;
