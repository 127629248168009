import React, { useEffect, useRef, useState } from "react";
import "./categoryProject.css";
import { toast } from "react-toastify";
import { IoCloseCircleSharp } from "react-icons/io5";

const CategoryProject = () => {
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [category, setCategory] = useState("");
  const [allDataCategory, setAllDataCategory] = useState([]);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSubmitCategory = async (event) => {
    event.preventDefault();

    setDisabledSubmit(true);

    const FormDataCategory = new FormData();
    FormDataCategory.append("category", category);
    FormDataCategory.append("validation", validation);

    const response = await fetch(`${LinkLikeBachend}create/categories.php`, {
      method: "POST",
      body: FormDataCategory,
    });

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("Data added successfully");

        setCategory("");

        setDisabledSubmit(false);

        fetchDataCategory();
      } else {
        toast.error("Error when adding data");

        console.log(resultText);
        setDisabledSubmit(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error when adding data");
      console.log("Error:", errorText);
      console.log(errorText);
      setDisabledSubmit(false);
    }
  };

  const fetchDataCategory = async () => {
    const formDataViewCategory = new FormData();
    formDataViewCategory.append("validation", validation);

    try {
      const responseCategory = await fetch(
        `${LinkLikeBachend}read/view_category.php`,
        {
          method: "POST",
          body: formDataViewCategory,
        }
      );
      const dataCategory = await responseCategory.json();
      setAllDataCategory(dataCategory);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  let logLoad = useRef(true);

  useEffect(() => {
    if (logLoad.current) {
      logLoad.current = false;
      fetchDataCategory();
    }
  }, []);

  const handleDeleteCategory = async (idCategory) => {
    const FormDataIdCategory = new FormData();
    FormDataIdCategory.append("validation", validation);
    FormDataIdCategory.append("idCategory", idCategory);

    const response = await fetch(
      `${LinkLikeBachend}delete/delete_category.php`,
      {
        method: "POST",
        body: FormDataIdCategory,
      }
    );

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The data has been deleted successfully");

        fetchDataCategory();
      } else {
        toast.error("Error when deleting data");

        console.log(resultText);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error when deleting data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
    }
  };

  // console.log(allDataCategory);

  return (
    <div className="category">
      <h2>Category Project</h2>

      <form className="category_form" onSubmit={handleSubmitCategory}>
        <input
          type="text"
          name="product_category"
          id="product_category"
          placeholder="Name Category"
          required
          value={category}
          onChange={handleChange}
        />

        <input
          type="submit"
          value="Add Category"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
        />
      </form>
      <div className="all_data_category">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            {allDataCategory && allDataCategory.length > 0 ? (
              allDataCategory.map((category, i) => (
                <tr key={i}>
                  <td>
                    <IoCloseCircleSharp
                      onClick={() => handleDeleteCategory(category.id)}
                    />
                  </td>
                  <td>{category.category_name}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>NO Data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CategoryProject;
