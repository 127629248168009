import React, { useEffect, useRef, useState } from "react";
import "./adminProject.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AdminProject = () => {
  const [allDataProject, setAllDataProject] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataprojectCount = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);

      const responseprojectCount = await fetch(
        `${LinkLikeBachend}read/view_project_count.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataprojectCount = await responseprojectCount.text();

      setProjectCount(dataprojectCount);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchDataProject = async () => {
    const formDataViewProject = new FormData();
    formDataViewProject.append("validation", validation);

    try {
      const responseProject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewProject,
        }
      );
      const dataProject = await responseProject.json();
      setAllDataProject(dataProject);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewprojectPage = new FormData();
    formDataViewprojectPage.append("validation", validation);
    formDataViewprojectPage.append("page", currentPage + 1);

    try {
      const responseproject = await fetch(
        `${LinkLikeBachend}read/view_project.php`,
        {
          method: "POST",
          body: formDataViewprojectPage,
        }
      );
      const dataproject = await responseproject.json();

      setTimeout(() => {
        setAllDataProject((prevData) => prevData.concat(dataproject));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  let logLoad = useRef(true);

  useEffect(() => {
    if (logLoad.current) {
      logLoad.current = false;
      fetchDataProject();
      fetchDataprojectCount();
    }
  }, []);

  const handleDeleteProject = async (idProject) => {
    const FormDataIdProject = new FormData();
    FormDataIdProject.append("validation", validation);
    FormDataIdProject.append("idProject", idProject);

    const response = await fetch(
      `${LinkLikeBachend}delete/delete_project.php`,
      {
        method: "POST",
        body: FormDataIdProject,
      }
    );

    const resultText = await response.text();

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("The data has been deleted successfully");

        fetchDataProject();
        fetchDataprojectCount();
      } else {
        toast.error("Error when deleting data");

        console.log(resultText);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error when deleting data");
      console.log("حدث خطأ:", errorText);
      console.log(errorText);
    }
  };

  const handleUpdateProject = async (idProject) => {
    navigate(`/dashboard/update-project/${idProject}`);
  };

  // console.log(allDataProject);
  // console.log(projectCount);

  return (
    <div className="admin_project">
      <div className="all_admin_project">
        {allDataProject &&
          allDataProject.length > 0 &&
          allDataProject.map((item, i) => (
            <div className="admin_project_item" key={i}>
              <img
                src={`${LinkLikeBachend}${item.img_project}`}
                alt={item.title_project_ar}
              />

              <h4>{item.title_project_en}</h4>

              <h4>{item.title_project_ar}</h4>

              <button
                className="button"
                onClick={() => handleDeleteProject(item.id)}
              >
                delete
              </button>

              <button
                className="button"
                onClick={() => handleUpdateProject(item.id)}
              >
                update
              </button>
            </div>
          ))}
      </div>

      <div className="div_load_more">
        {allDataProject &&
          allDataProject.length > 0 &&
          parseInt(projectCount) !== allDataProject.length && (
            <button
              className="button load_more"
              onClick={handleLoadMore}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              {!isDisabledLoadMore ? "Load More" : "Loading..."}
            </button>
          )}
      </div>
    </div>
  );
};

export default AdminProject;
