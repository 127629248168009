import React, { useState } from "react";
import { toast } from "react-toastify";
import "./changePasswordAdmin.css";

const ChangePasswordAdmin = () => {
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const [isDisabled, setDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    const formDataLogin = new FormData(e.target);
    formDataLogin.append("validation", validation);

    const response = await fetch(
      `${LinkLikeBachend}update/admin_change_password.php`,
      {
        method: "POST",
        body: formDataLogin,
      }
    );

    const resultText = await response.text();

    setDisabled(false);

    if (response.ok) {
      if (resultText == "successfully") {
        toast.success("Password has been updated");
        setDisabled(false);

        e.target.reset();
      } else {
        toast.error("Error when updating password");
        console.log(resultText);
        setDisabled(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error when updating password");
      console.log(errorText);
      setDisabled(false);
    }
  };

  return (
    <div className="login_admin" onSubmit={handleSubmit}>
      <div className="box">
        <span className="borderLine"></span>
        <form>
          <h2>Change Password Admin</h2>

          <div className="inputBox">
            <input type="password_admin" name="password_admin" required />
            <span>Password</span>
            <i></i>
          </div>

          <input
            type="submit"
            style={{ opacity: isDisabled ? 0.3 : 1 }}
            value={isDisabled ? "Loading..." : "Save"}
            disabled={isDisabled}
          />
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordAdmin;
