import React, { useCallback, useEffect, useRef, useState } from "react";
import background_img from "../../../../img/background_img.webp";
import background_video from "../../../../img/background_video.webp";
import "../addProject.css";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { TbTextSize } from "react-icons/tb";
import { FaImage, FaVideo } from "react-icons/fa";
import { ImEmbed2 } from "react-icons/im";
import { IoCloseCircle } from "react-icons/io5";
import { FaAnglesDown, FaAnglesUp } from "react-icons/fa6";

const AddProject = () => {
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [allDataCategory, setAllDataCategory] = useState([]);
  const { handleSubmit, control, setValue, watch, reset } = useForm();
  const textFields = watch("textFields", []);

  const [watchData, setWatchData] = useState(true);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataCategory = async () => {
    const formDataViewCategory = new FormData();
    formDataViewCategory.append("validation", validation);

    try {
      const responseCategory = await fetch(
        `${LinkLikeBachend}read/view_category.php`,
        {
          method: "POST",
          body: formDataViewCategory,
        }
      );
      const dataCategory = await responseCategory.json();
      setAllDataCategory(dataCategory);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const rangePaddingValue = watch("range_padding");

  const fetchWatchData = useCallback(() => {}, [watchData]);

  let logLoad = useRef(true);

  useEffect(() => {
    if (logLoad.current) {
      logLoad.current = false;
      fetchDataCategory();
    }
  }, [fetchWatchData]);

  const addField = (type) => {
    setValue("textFields", [...textFields, { type, value: "" }]);
  };

  const removeField = (index) => {
    setWatchData(false);

    const updatedTextFields = [...textFields];
    updatedTextFields.splice(index, 1);
    setValue("textFields", updatedTextFields);
    setTimeout(() => {
      setWatchData(true);
    }, 1000);
  };

  const moveFieldUp = (index) => {
    setWatchData(false);

    if (index > 0) {
      const updatedTextFields = [...textFields];
      const tempValue = updatedTextFields[index];
      updatedTextFields[index] = updatedTextFields[index - 1];
      updatedTextFields[index - 1] = tempValue;
      setValue("textFields", updatedTextFields);
    }

    setTimeout(() => {
      setWatchData(true);
    }, 500);
  };

  const moveFieldDown = (index) => {
    setWatchData(false);
    if (index < textFields.length - 1) {
      const updatedTextFields = [...textFields];
      const tempValue = updatedTextFields[index];

      updatedTextFields[index] = updatedTextFields[index + 1];
      updatedTextFields[index + 1] = tempValue;
      setValue("textFields", updatedTextFields);
    }

    setTimeout(() => {
      setWatchData(true);
    }, 500);
  };

  const onSubmit = async (data) => {
    setDisabledSubmit(true);

    // console.log(data);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);

    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "textFields") {
        FormDataSubmit.append(key, data[key]);
      }
    }

    if (data.textFields) {
      data.textFields.forEach((textField, index) => {
        if (textField.type === "image" && textField.value instanceof File) {
          FormDataSubmit.append(`image[${index}]`, textField.value);
        } else if (
          textField.type === "video" &&
          textField.value instanceof File
        ) {
          FormDataSubmit.append(`video[${index}]`, textField.value);
        } else {
          FormDataSubmit.append(`textFields[${index}]`, textField.value);
        }
      });
    }

    const response = await fetch(`${LinkLikeBachend}create/project.php`, {
      method: "POST",
      body: FormDataSubmit,
    });

    const resultText = await response.text();

    if (response.ok) {
      setDisabledSubmit(false);

      if (resultText == "successfully") {
        let img_project = document.querySelector("#img_project");
        img_project.value = "";

        setDisabledSubmit(false);

        toast.success("Data added successfully");

        reset();
      } else {
        toast.error("Error when adding data");

        console.log(resultText);
        setDisabledSubmit(false);
      }
    } else {
      const errorText = await response.text();
      toast.error("Error when adding data");
      console.log("Error:");
      console.log(errorText);
      setDisabledSubmit(false);
    }
  };

  // console.log(allDataCategory);

  return (
    <div className="project_admin">
      <h2>Add Project</h2>
      <form
        className="project_form"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div className="added_content">
          {textFields.length == 0 && (
            <p className="start">Start building your project</p>
          )}

          {watchData &&
            textFields.map((field, index) => (
              <div
                key={index}
                className="full_images_video_added"
                style={{ paddingBottom: `${rangePaddingValue}px` }}
              >
                {field.type === "text" && (
                  <>
                    <div>
                      <label>{`Text ${index + 1}:`}</label>
                      <Controller
                        name={`textFields[${index}].value`}
                        control={control}
                        defaultValue={field.value}
                        render={({ field }) => <input {...field} required />}
                      />
                    </div>

                    <IoCloseCircle
                      className="remove_field"
                      onClick={() => removeField(index)}
                    />

                    <div className="down_up_item">
                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldUp(index)}
                        disabled={index === 0}
                      >
                        <FaAnglesUp />
                      </button>

                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldDown(index)}
                        disabled={index === textFields.length - 1}
                      >
                        <FaAnglesDown />
                      </button>
                    </div>
                  </>
                )}

                {field.type === "image" && (
                  <>
                    <div>
                      <label>{`Image ${index + 1}:`}</label>
                      <Controller
                        name={`textFields[${index}].value`}
                        control={control}
                        defaultValue={field.value}
                        render={({ field }) => (
                          <>
                            <div className="images_added">
                              <input
                                type="file"
                                accept="image/*"
                                {...(field.value ? {} : { required: true })}
                                onChange={(e) =>
                                  field.onChange(e.target.files[0])
                                }
                              />
                              <img
                                src={
                                  field.value
                                    ? URL.createObjectURL(field.value)
                                    : background_img
                                }
                                alt={`Image ${index + 1}`}
                              />
                            </div>

                            <IoCloseCircle
                              className="remove_field"
                              onClick={() => removeField(index)}
                            />
                          </>
                        )}
                      />
                    </div>

                    <div className="down_up_item">
                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldUp(index)}
                        disabled={index === 0}
                      >
                        <FaAnglesUp />
                      </button>

                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldDown(index)}
                        disabled={index === textFields.length - 1}
                      >
                        <FaAnglesDown />
                      </button>
                    </div>
                  </>
                )}

                {field.type === "video" && (
                  <>
                    <div>
                      <label>{`video ${index + 1}:`}</label>
                      <Controller
                        name={`textFields[${index}].value`}
                        control={control}
                        defaultValue={field.value}
                        render={({ field }) => (
                          <>
                            <div className="video_added">
                              <div className="video_added_icon">
                                <input
                                  type="file"
                                  accept="video/*"
                                  {...(field.value ? {} : { required: true })}
                                  onChange={(e) =>
                                    field.onChange(e.target.files[0])
                                  }
                                />

                                <img
                                  src={background_video}
                                  alt="background_video"
                                  className="background_video"
                                />
                              </div>
                              <video
                                controls
                                src={
                                  field.value
                                    ? URL.createObjectURL(field.value)
                                    : null
                                }
                              ></video>
                            </div>

                            <IoCloseCircle
                              className="remove_field"
                              onClick={() => removeField(index)}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div className="down_up_item">
                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldUp(index)}
                        disabled={index === 0}
                      >
                        <FaAnglesUp />
                      </button>

                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldDown(index)}
                        disabled={index === textFields.length - 1}
                      >
                        <FaAnglesDown />
                      </button>
                    </div>
                  </>
                )}

                {field.type === "embed" && (
                  <>
                    <div>
                      <label>{`Embed ${index + 1}:`}</label>
                      <Controller
                        name={`textFields[${index}].value`}
                        control={control}
                        defaultValue={field.value}
                        render={({ field }) => <textarea {...field} required />}
                      />
                    </div>

                    <IoCloseCircle
                      className="remove_field"
                      onClick={() => removeField(index)}
                    />

                    <div className="down_up_item">
                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldUp(index)}
                        disabled={index === 0}
                      >
                        <FaAnglesUp />
                      </button>

                      <button
                        className="down_up_button"
                        type="button"
                        onClick={() => moveFieldDown(index)}
                        disabled={index === textFields.length - 1}
                      >
                        <FaAnglesDown />
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>

        <div className="data_form">
          <div className="add_containt">
            <button type="button" onClick={() => addField("image")}>
              <p>
                <FaImage />
              </p>
              Image
            </button>
            <button type="button" onClick={() => addField("text")}>
              <p>
                <TbTextSize />
              </p>
              Text
            </button>
            <button type="button" onClick={() => addField("video")}>
              <p>
                <FaVideo />
              </p>
              Video
            </button>

            <button type="button" onClick={() => addField("embed")}>
              <p>
                <ImEmbed2 />
              </p>
              Embed
            </button>
          </div>

          <div className="all_info">
            <div>
              <label>Featured image:</label>
              <Controller
                name="img_project"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="div_img_project">
                    <input
                      type="file"
                      accept="image/*"
                      required
                      onChange={(e) => {
                        field.onChange(e.target.files[0]);
                      }}
                      id="img_project"
                    />

                    <img
                      src={
                        field.value
                          ? URL.createObjectURL(field.value)
                          : background_img
                      }
                      alt="background_img"
                    />
                  </div>
                )}
              />
            </div>

            <div className="all_data_category">
              <label>category:</label>
              <Controller
                name="category_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <select {...field} required id="category_name">
                    <option value="">Choose the category</option>
                    {allDataCategory &&
                      allDataCategory.length > 0 &&
                      allDataCategory.map((item, i) => (
                        <option key={i} value={item.id}>
                          {item.category_name}
                        </option>
                      ))}
                  </select>
                )}
              />
            </div>

            <div className="title_project">
              <label htmlFor="title_project_ar">Title (AR):</label>
              <Controller
                name="title_project_ar"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="text"
                    id="title_project_ar"
                    placeholder="عنوان"
                    required
                    {...field}
                  />
                )}
              />
            </div>

            <div className="title_project">
              <label htmlFor="title_project_en">Title (EN):</label>
              <Controller
                name="title_project_en"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="text"
                    id="title_project_en"
                    placeholder="title en"
                    required
                    {...field}
                  />
                )}
              />
            </div>

            <div className="range_padding">
              <label htmlFor="range_padding">
                padding {rangePaddingValue == "" ? 0 : rangePaddingValue}
              </label>
              <Controller
                name="range_padding"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="range"
                    id="range_padding"
                    min="0"
                    max="100"
                    required
                    {...field}
                  />
                )}
              />
            </div>
          </div>

          <input
            type="submit"
            value="Add"
            disabled={isDisabledSubmit}
            style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
          />
        </div>
      </form>
    </div>
  );
};

export default AddProject;
