import React from "react";
import "./about.css";
import { useTranslation } from "react-i18next";
import pioneer_planet from "../../../img/pioneer_planet.webp";
import Counter from "./counter/Counter";
import { NavLink } from "react-router-dom";

const About = () => {
  const { t } = useTranslation();

  return (
    <div id="about">
      <div className="about">
        <div className="left">
          <img
            src={pioneer_planet}
            alt="pioneer_planet"
            className="rotate-and-move"
          />
        </div>
        <div className="right">
          <h3>{t("about")}</h3>
          <h2>{t("name_company")}</h2>
          <h3 className="info_about">{t("info_about")}</h3>
          <div className="count">
            <div>
              <p>
                <Counter endNumber={"08"} />
                <span>+</span>
              </p>
              <p>{t("about_num_1")}</p>
            </div>
            <div>
              <p>
                <Counter endNumber={"500"} />
                <span>+</span>
              </p>
              <p>{t("about_num_2")}</p>
            </div>
            <div>
              <p>
                <Counter endNumber={"20"} />
                <span>+</span>
              </p>
              <p>{t("about_num_3")}</p>
            </div>
          </div>

          <h3 className="last_h3_1">{t("about_text_botton_1")}</h3>
          <NavLink to="contact-us" className="last_h3_2">
            {t("about_text_botton_2")}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default About;
