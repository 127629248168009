import React, { useEffect, useRef } from "react";
import "./portfolio.css";
import FaStar from "../../img/star.webp";
import slider_1 from "../../img/1.png";
import slider_2 from "../../img/2.png";
import slider_3 from "../../img/3.png";
import { useTranslation } from "react-i18next";
import buttonPortfolio from "../../img/button.png";
import ProjectContent from "./projectContent/ProjectContent";
import { Link } from "react-scroll";
import ScrollVisibility from "../../components/ScrollVisibility/ScrollVisibility";

const Portfolio = () => {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <img src={FaStar} alt="FaStar" key={index} />
  ));
  const { t } = useTranslation();

  const logLoad = useRef(true);

  useEffect(() => {
    if (logLoad.current) {
      logLoad.current = false;

      let copy_1 = document.querySelector(".img_slide_1").cloneNode(true);
      let copy_2 = document.querySelector(".img_slide_2").cloneNode(true);
      let copy_3 = document.querySelector(".img_slide_3").cloneNode(true);

      document.querySelector(".slide_1").appendChild(copy_1);
      document.querySelector(".slide_2").appendChild(copy_2);
      document.querySelector(".slide_3").appendChild(copy_3);
    }
  }, []);

  return (
    <div className="portfolio">
      <ScrollVisibility>
        <div className="top">
          <div className="left">
            <h2>{t("have_your_dream")}</h2>
            <div className="buttom_trusted_customer">
              <div className="full_star">
                <div className="star">{stars}</div>
                <p>12000+</p>
                <p>{t("trusted_customer")}</p>
              </div>

              <Link
                to="projectContent"
                spy={true}
                smooth={true}
                offset={-250}
                duration={500}
                className="view_all_project"
              >
                <span>{t("portfolio")}</span>
                <img src={buttonPortfolio} alt="buttonPortfolio" />
              </Link>
            </div>
          </div>
          <div className="right">
            <div className="slider">
              <div className="slide_1">
                <div className="img_slide_1">
                  <img src={slider_1} alt="slider_1" />
                </div>
              </div>

              <div className="slide_2">
                <div className="img_slide_2">
                  <img src={slider_2} alt="slider_2" />
                </div>
              </div>

              <div className="slide_3">
                <div className="img_slide_3">
                  <img src={slider_3} alt="slider_3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollVisibility>

      <ScrollVisibility>
        <div className="bottom">
          <div className="title">
            <p>{t("cases")}</p>
            <h2>
              <span>{t("project")}</span>
            </h2>
          </div>

          <ProjectContent />
        </div>
      </ScrollVisibility>
    </div>
  );
};

export default Portfolio;
