import React from "react";
import "./customerOpinions.css";
import { IoIosStar } from "react-icons/io";
import { IoIosStarHalf } from "react-icons/io";
import { IoIosStarOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";
import userimg from "../../img/user.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const CustomerOpinions = () => {
  const { t } = useTranslation();

  const customerOpinions = [
    {
      icon: userimg,
      name: "ahmed mamdouh",
      star: 1.5,
      reviews:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam, quam!",
    },
    {
      icon: userimg,
      name: "sid ahmed",
      star: 2,
      reviews:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis doloremque rem in illo dignissimos temporibus!",
    },
    {
      icon: userimg,
      name: "manar ahmed",
      star: 5,
      reviews:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt fugit perspiciatis consequatur dicta fugiat soluta suscipit delectus harum iusto possimus, veniam aspernatur distinctio alias commodi ex error eligendi culpa voluptatibus. Tenetur accusamus, nostrum corrupti possimus facilis assumenda dignissimos? Aliquid placeat, aliquam similique neque quidem assumenda officia illo alias commodi voluptas unde impedit officiis, ab saepe!",
    },
    {
      icon: userimg,
      name: "mido salmon",
      star: 3,
      reviews: "Lorem ipsum dolor sit amet.",
    },
  ];

  const OpinionStars = ({ star }) => {
    const filledStars = Math.floor(star);
    const halfStar = star % 1 !== 0;

    const filledStarElements = [];
    for (let i = 0; i < filledStars; i++) {
      filledStarElements.push(
        <span key={i} className="star-filled">
          <IoIosStar />
        </span>
      );
    }

    if (halfStar) {
      filledStarElements.push(
        <span key="half" className="star-half">
          <IoIosStarHalf />
        </span>
      );
    }

    while (filledStarElements.length < 5) {
      filledStarElements.push(
        <span key={filledStarElements.length} className="star-empty">
          <IoIosStarOutline />
        </span>
      );
    }

    return <div className="opinion-stars">{filledStarElements}</div>;
  };

  const extractText = (text, textLength) => {
    return (
      text &&
      `${text.substring(0, textLength)}${text.length > textLength ? "..." : ""}`
    );
  };

  return (
    <div id="customerOpinions">
      <p>{t("customer_reviews")}</p>

      <div className="customerOpinions">
        <Swiper
          slidesPerView={3}
          spaceBetween={80}
          loop={true}
          // pagination={{
          //   clickable: true,
          // }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            705: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            0: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {customerOpinions.map((opinion, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <div className="opinion">
                <div className="icon">
                  <img src={opinion.icon} alt={opinion.name} />
                </div>
                <div className="name">{extractText(opinion.name, 10)}</div>
                <OpinionStars star={opinion.star} />
                <div className="reviews">
                  {extractText(opinion.reviews, 50)}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CustomerOpinions;
