import React, { useEffect, useState } from "react";
import "./homeAdmin.css";
import { SiVirustotal } from "react-icons/si";

const HomeAdmin = () => {
  const [allDataTotal, setAllDataTotal] = useState({});
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataTotal = async () => {
    try {
      const formDataCount = new FormData();
      formDataCount.append("validation", validation);

      const responseDataTotal = await fetch(
        `${LinkLikeBachend}read/total_result.php`,
        {
          method: "POST",
          body: formDataCount,
        }
      );

      const dataDataTotal = await responseDataTotal.json();

      setAllDataTotal(dataDataTotal);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataTotal();
  }, []);

  console.log(allDataTotal);

  return (
    <div className="dashboard">
      <div className="dashboard_deposit">
        <div className="deposit_total">
          <SiVirustotal />
          <p>{allDataTotal?.totalCategories}</p>

          <small>Total Categories</small>
        </div>
        <div className="deposit_total">
          <SiVirustotal />
          <p>{allDataTotal?.totalProject}</p>

          <small>Total Project</small>
        </div>
        <div className="deposit_total">
          <SiVirustotal />
          <p>{allDataTotal?.totalClients}</p>

          <small>Total Clients</small>
        </div>
      </div>
    </div>
  );
};

export default HomeAdmin;
