import React, { useEffect, useRef, useState } from "react";
import "./sliderClients.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const SliderClients = () => {
  const [allDataSlider, setAllDataSlider] = useState([]);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataSlider = async () => {
    const formDataViewSlider = new FormData();
    formDataViewSlider.append("validation", validation);

    try {
      const responseSlider = await fetch(
        `${LinkLikeBachend}read/view_slider_clients.php`,
        {
          method: "POST",
          body: formDataViewSlider,
        }
      );
      const dataSlider = await responseSlider.json();
      setAllDataSlider(dataSlider);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const logLoad = useRef(true);

  useEffect(() => {
    if (logLoad.current) {
      logLoad.current = false;

      fetchDataSlider();
    }
  }, []);

  // console.log(allDataSlider);

  return (
    <div className="slider_clients">
      <Swiper
        slidesPerView={5}
        spaceBetween={80}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          1024: {
            slidesPerView: 6,
            spaceBetween: 80,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 80,
          },
          0: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {allDataSlider &&
          allDataSlider.length > 0 &&
          allDataSlider.map((image, i) => (
            <SwiperSlide key={i} virtualIndex={i}>
              <img
                src={`${LinkLikeBachend}${image.img_clients}`}
                alt={`img slider${i}`}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default SliderClients;
