import React from "react";
import Header from "./header/Header";
import About from "./about/About";
import Project from "./project/Project";
import Services from "./services/Services";
import Clients from "./clients/Clients";
import ScrollVisibility from "../../components/ScrollVisibility/ScrollVisibility";
import CustomerOpinions from "../customerOpinions/CustomerOpinions";

const Home = () => {
  return (
    <>
      <ScrollVisibility>
        <Header />
      </ScrollVisibility>
      <ScrollVisibility>
        <About />
      </ScrollVisibility>
      <ScrollVisibility>
        <Project />
      </ScrollVisibility>
      <ScrollVisibility>
        <Services />
      </ScrollVisibility>
      <ScrollVisibility>
        <CustomerOpinions />
      </ScrollVisibility>
      <ScrollVisibility>
        <Clients />
      </ScrollVisibility>
    </>
  );
};

export default Home;
